<template>
  <article>
    <!-- <div
      class="menu-image"
      :style="{ backgroundImage: `url(${data.imgUrl})` }">
    </div> -->
    <!-- :class="!isHover ? 'img-mouse-leave' : ''" -->

    <div class="wrap-image">
      <div
        v-if="showModal"
        :style="[ showModal ? { 'opacity': '1' } : { 'opacity': '0' } ]"
        class="modal" >
        <div
          @click="showModal = false"
          class="close" >
          <div class="line-1"></div>
          <div class="line-2"></div>
        </div>
        <router-link
          id="howToBuy"
          :to="{ name: 'application' }"
          @click.native="toApplication" >
          How To Buy
        </router-link>
        <div class="line"></div>
      </div>

      <div
        v-if="!showModal"
        style="width: 100%; height: 100%;" >
        <div
          v-if="data.other.length === 0"
          style="overflow: hidden; width: 100%; height: 100%;" >
          <img
            @click="showModal = true"
            v-lazy="data.imgUrl"
            @load="loaded"
            :alt="data.name" >
        </div>

        <hooper
          v-else
          @slide="updateCarousel" >
          <slide>
            <img
              @click="showModal = true"
              v-lazy="data.imgUrl"
              @load="loaded"
              :alt="data.name" >
          </slide>
          <slide
            v-for="(other, i) in data.other"
            :key="i" >
            <img
              @click="showModal = true"
              v-lazy="other.imgUrl"
              @load="loaded"
              :alt="data.name" >
          </slide>

          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div>
    </div>

    <div class="content">
      <span class="category">{{ categoryName }}</span>
      <h4>{{ data.name }}</h4>
      <span
        v-if="data.other.length === 0"
        class="price">
        {{ data.price }}
      </span>
      <div
        v-if="data.other.length !== 0"
        style="display: flex; justify-content: center; margin-left: 12px;" >
        <span
          v-for="(other, i) in data.other"
          :key="other.id"
          class="price"
          style="display: flex;" >
          <span
            v-if="i === 0"
            :style="[ i === currentSlide ? { 'opacity': '1' } : { 'opacity': '0.3' } ]"
            style="display: flex;" >
            {{ data.price }}
          </span>
          <div
            :style="[ i + 1 === currentSlide ? { 'opacity': '1' } : { 'opacity': '0.3' } ]"
            style="display: flex;" >
            {{ other.price }}
          </div>
        </span>
      </div>
    </div>
  </article>
</template>

<script>
import { mapState } from 'vuex'
import {
  Hooper,
  Slide,
  Pagination as HooperPagination
} from 'hooper'

export default {
  components: {
    Hooper,
    Slide,
    HooperPagination
  },

  props: {
    data: Object
  },

  data () {
    return {
      categoryName: '',
      isLoadImg: false,
      currentSlide: 0,
      showModal: false
    }
  },

  computed: {
    ...mapState({
      rawCategories: state => state.menu.rawCategories
    })
  },

  methods: {
    loaded () {
      this.isLoadImg = false
    },

    defaultImg () {
      this.imgUrl = this.data.imgUrl
    },

    mapCategory () {
      if (this.rawCategories) {
        let cateFilter = this.rawCategories.filter(cate => cate.id.toString() === this.data.category)
        this.categoryName = cateFilter[0]?.name
      }
    },

    updateCarousel (payload) {
      this.currentSlide = payload.currentSlide
    },

    toApplication () {
      this.showModal = false
    }
  },

  created () {
    this.defaultImg()
    this.mapCategory()
  },

  watch: {
    data () {
      this.defaultImg()
      this.mapCategory()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

article {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;

  .wrap-image {
    display: flex;
    width: 100%;
    height: 380px;

    @media screen and (min-width: 768px) {
      height: 34vw;
    }

    @media screen and (min-width: 1024px) {
      height: 18vw;
    }

    .modal {
      opacity: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      transition: 800ms $ease;

      .close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin: 16px;
        height: 28px;

        .line-1,
        .line-2
        {
          width: 32px;
          height: 3px;
          background-color: white;
        }

        .line-1 {
          transform: rotate(45deg)
                     translate(7px, 9px);
        }

        .line-2 {
          transform: rotate(-45deg)
                     translate(-6px, 6px);
        }
      }

      a {
        cursor: pointer;
        font-family: 'DB Ozone X Bd';
        font-size: 32px;
        color: white;
      }

      .line {
        margin-top: 15px;
        width: 80px;
        height: 4px;
        background-color: $color-main;
      }
    }

    img {
      width: 100%;
      height: 100%;
      background-color: #CCC;
    }

    .hooper {
      height: 100%;
    }
  }

  .content {
    margin-top: 36px;
    margin-bottom: 12px;
    text-align: center;

    .category {
      font-family: 'DB Ozone X Bd';
    }

    h4 {
      font-family: 'DB Ozone X Bd';
      font-size: 22px;
    }

    .price {
      align-items: flex-end;
      font-family: 'DB Ozone X Bd';
      font-size: 20px;

      span {
        font-family: 'DB Ozone X Bd';
      }

      span::after {
        content: '/';
        color: $color-main;
        padding: 0 5px;
      }
    }

    .price::after {
      content: '/';
      color: $color-main;
      padding: 0 5px;
    }

    .price:last-child::after{
      content:  '';
    }
  }
}
</style>
